import "./components/buttons.css"
import "./components/edittext.scss"
import Toast from "./components/toast/index.jsx"
import React, { useState } from "react";
import firebase from 'firebase/compat/app';
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}

const db = getFirestore(firebase.app());
const storage = getStorage();

function AddChantLayout(props) {
  const [toastTitle, setToastTitle] = useState("File caricato con Successo!");
  const [toastDesc, setToastDesc] = useState("Il file sarà presto disponibile dal menu");
  const [addChantText, setAddChantText] = useState("Nuovo canto");

  let timer1, timer2;
  const toast = document.querySelector(".toast");
  const closeIcon = document.querySelector(".close");
  const progress = document.querySelector(".progress");

  function addChantClick() {
    if (addChantText == "Nuovo canto") {
      setAddChantText("Aggiungi");
      document.getElementById("infoForm").classList.remove("hidden");
      document.getElementById("file").classList.remove("hidden");
    } else {
      pushToDatabase();
    }
  }

  async function pushToDatabase() {
    if (document.getElementById('title').value == "" || document.getElementById('file').files.length == 0) {
      setToastTitle("Manca il file o il titolo");
      setToastDesc("Assicurati di aver selezionato il file e aver messo il titolo");
      toast.classList.add("active");
      timer1 = setTimeout(() => {
        toast.classList.remove("active");
      }, 2000);
      return;
    }

    const now = new Date();
    const currentTime = now.toLocaleTimeString();
    let file = document.getElementById('file').files[0];
    let filepath = 'files/' + document.getElementById('title').value + "_" + currentTime;
    try {
      const docRef = await addDoc(collection(db, "chants"), {
        title: document.getElementById('title').value,
        pdf: filepath,
        modified: -Date.now(),
        testo: "files/spartito_non_disponibile.pdf",
        strumenti: "not_available"
      });
      setToastTitle("Upload in corso");
      setToastDesc("Attendi qualche secondo prima del caricamento completo");
      toast.classList.add("active");
      progress.classList.add("active");
      timer1 = setTimeout(() => {
        toast.classList.remove("active");
      }, 3000);
      timer2 = setTimeout(() => {
        progress.classList.remove("active");
      }, 3300);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    uploadBytes(ref(storage, filepath), file).then((snapshot) => {
      setToastTitle("File caricato con Successo!");
      setToastDesc("Il file sarà presto disponibile dal menu");
      setAddChantText("Nuovo canto");
      document.getElementById("infoForm").classList.add("hidden");
      document.getElementById("file").classList.add("hidden");
      toast.classList.add("active");
      progress.classList.add("active");
      timer1 = setTimeout(() => {
        toast.classList.remove("active");
      }, 5000);
      timer2 = setTimeout(() => {
        progress.classList.remove("active");
        props.reloadLatestChant();
      }, 5300);
    });

    document.getElementById('title').value = "";
    document.getElementById('file').value = "";
  }

  function setTitleFromPdf() {
    var str = document.getElementById('file').value;
    document.getElementById('title').value = str.substring(str.lastIndexOf("\\") + 1, str.lastIndexOf("."));
  }

  return (
    <>
      <Toast title={toastTitle} text={toastDesc} />
      <div className="self-start w-screen px-20 ml-20 py-20 fixed z-10 bg-white">
        <button className="float-left self-start button-5" onClick={addChantClick} role="button">{addChantText}</button>
        <div className="float-left px-14 self-start form__group field hidden" id="infoForm">
          <input type="input" autoComplete="off" className="form__field" placeholder="Title" name="title" id='title' />
          <label htmlFor="title" className="form__label">Titolo</label>
        </div>
        <input id="file" onChange={setTitleFromPdf} className="w-2/6 hidden" type="file" />
      </div>
    </>
  )
}

export default AddChantLayout;
