import "./utils/components/searchbox.css"

import Card from "./utils/Card.jsx"
import AddChantLayout from "./utils/AddChantLayout.jsx"
import CardsWrap from "./utils/CardsWrap.jsx"

import React, { useState, useEffect, forceUpdate, useRef } from "react";

import { getFirestore, collection, query, where, getDocs, doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { getStorage, ref, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";

import $ from 'jquery';
import { FaTrashAlt, FaEdit, } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';


//firebase consts
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}
const db = getFirestore(firebase.app());

function Chants() {
  const [activeCardTitle, setActiveCardTitle] = React.useState("");
  const [activeCardPdf, setActiveCardPdf] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardTesto, setActiveCardTesto] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardStrumenti, setActiveCardStrumenti] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardId, setActiveCardId] = React.useState("");
  const [uploadingFileType, setUploadingFileType] = React.useState("bassi");
  const [isBassiUploaded, setIsBassiUploaded] = React.useState(false);
  const [isBassi1Uploaded, setIsBassi1Uploaded] = React.useState(false);
  const [isBassi2Uploaded, setIsBassi2Uploaded] = React.useState(false);
  const [isTenoriUploaded, setIsTenoriUploaded] = React.useState(false);
  const [isTenori1Uploaded, setIsTenori1Uploaded] = React.useState(false);
  const [isTenori2Uploaded, setIsTenori2Uploaded] = React.useState(false);
  const [isSopraniUploaded, setIsSopraniUploaded] = React.useState(false);
  const [isSoprani1Uploaded, setIsSoprani1Uploaded] = React.useState(false);
  const [isSoprani2Uploaded, setIsSoprani2Uploaded] = React.useState(false);
  const [isContraltiUploaded, setIsContraltiUploaded] = React.useState(false);
  const [isContralti1Uploaded, setIsContralti1Uploaded] = React.useState(false);
  const [isContralti2Uploaded, setIsContralti2Uploaded] = React.useState(false);
  const [isCoroUploaded, setIsCoroUploaded] = React.useState(false);
  const [isCoro1Uploaded, setIsCoro1Uploaded] = React.useState(false);
  const [isCoro2Uploaded, setIsCoro2Uploaded] = React.useState(false);
  const [isSalmistaUploaded, setIsSalmistaUploaded] = React.useState(false);
  const [isSalmista1Uploaded, setIsSalmista1Uploaded] = React.useState(false);
  const [isSalmista2Uploaded, setIsSalmista2Uploaded] = React.useState(false);
  const [isSolistaUploaded, setIsSolistaUploaded] = React.useState(false);
  const [isSolista1Uploaded, setIsSolista1Uploaded] = React.useState(false);
  const [isSolista2Uploaded, setIsSolista2Uploaded] = React.useState(false);
  const [selectedPdfName, setSelectedPdfName] = React.useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [tagsSuggestions, setTagsSuggestions] = useState([]);

  const filteredTagsSuggestions = tagsSuggestions.filter((suggestion) =>
    suggestion.toLowerCase().includes(tagInput.toLowerCase())
  );

  function updateTagsSuggestions(updatedTags) {
    const tagsRef = doc(db, "utils", "existingTags");
    if (updatedTags != null) {
      let combinedSet = new Set(tagsSuggestions);
      updatedTags.forEach(tag => {
        combinedSet.add(tag);
      });
      setTagsSuggestions(Array.from(combinedSet));
      updateDoc(tagsRef, {
        tagsArray: Array.from(combinedSet)
      })
      return;
    }
    if (tagsSuggestions.length != 0) {
      return;
    }
    getDocumentsWithTags().then(documents => {
      let allTags = new Set(tagsSuggestions);  // Use the current state as the base

      documents.forEach(doc => {
        let tagsToAdd = [...new Set(doc.tags)];
        tagsToAdd.forEach(tag => allTags.add(tag));
      });

      // Convert the set to an array and update the Firestore document
      const updatedTagsSuggestions = [...allTags];
      setTagsSuggestions(updatedTagsSuggestions);  // Update the state

      updateDoc(tagsRef, {
        tagsArray: updatedTagsSuggestions
      });

    }).catch(error => {
      console.error("Error getting documents: ", error);
    });
  }

  function closeCard() {
    document.getElementById("card").style.height = "0%";
    setActiveCardPdf(`${process.env.PUBLIC_URL}/empty.pdf`);
    setActiveCardTesto(`${process.env.PUBLIC_URL}/empty.pdf`);
  }

  //on card click
  const openCard = (title, pdf, id, testo, strumenti, chantTags) => {
    updateTagsSuggestions();

    setActiveCardTitle(title);
    const storage = getStorage();
    setActiveCardId(id);
    getDownloadURL(ref(storage, pdf)).then((url) => {
      setActiveCardPdf(url);
      getMetadata(ref(storage, pdf))
        .then((metadata) => {
          console.log('Original File Name:', metadata.name);
          setSelectedPdfName(metadata.name);
        })
        .catch((metadataError) => {
          console.error('Error getting metadata:', metadataError);
        });
    }).catch((error) => { });
    console.log(activeCardTesto);
    getDownloadURL(ref(storage, testo)).then((url) => {
      setActiveCardTesto(url);
    }).catch((error) => { });
    getDownloadURL(ref(storage, strumenti)).then((url) => {
      setActiveCardStrumenti(url);
      console.log(url)
    }).catch((error) => { console.log(error) });
    document.getElementById("card").style.height = "100%";

    try {
      getDoc(doc(db, "chants", id)).then((doc) => {
        setTags(doc.data().tags);
        $("#chantTags").html("")
        doc.data().tags.forEach(tag => {
          const newTag = tag;
          $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
        });
      });
    } catch (error) {
      console.error("Error getting document:", error);
    }
    setUploadedFiles(id);
  }

  function appendSuffixToFilename(filename, suffix) {
    // Split the filename into its name and extension parts
    let parts = filename.split('.');

    // Assuming the last part is the extension, join everything except the last part
    let name = parts.slice(0, -1).join('.');

    // Append the suffix and then re-append the extension
    return name + suffix + '.' + parts[parts.length - 1];
  }

  function setUploadedFiles(id) {
    const parts = [
      "bassi", "bassi1", "bassi2",
      "tenori", "tenori1", "tenori2",
      "soprani", "soprani1", "soprani2",
      "contralti", "contralti1", "contralti2",
      "coro", "coro1", "coro2",
      "salmista", "salmista1", "salmista2",
      "solista", "solista1", "solista2"
    ];
    parts.forEach(part => setIsFileUploaded(part, id));
  }

  //delete dialog
  async function openDeleteDialog() {
    if (window.confirm("Eliminare il file?")) {
      await deleteDoc(doc(db, "chants", activeCardId));
      const storage = getStorage();
      deleteObject(ref(storage, activeCardPdf)).then(() => {
        reloadComponent();
      }).catch((error) => { });
    }
  }

  //reload component
  function reloadComponent() {
    $("#cards").load(window.location.href + "#cards");
  }

  const childRef = useRef();

  function reloadLatestChant() {
    if (childRef.current) {
      childRef.current.reloadLatestChantInWrap();
    }
  }

  function triggerFileInput(type) {
    document.getElementById('fileInputAudio').click();
    setUploadingFileType(type)
  }

  function handleFileUpload() {
    const fileInput = document.getElementById('fileInputAudio');
    const uploadedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    if (uploadedFile) {
      const storage = getStorage();
      const filepath = 'files/' + activeCardId + "_" + uploadingFileType + ".mp3";
      uploadBytes(ref(storage, filepath), uploadedFile).then((snapshot) => {
        setTimeout(() => {
          document.getElementById('fileInputAudio').value = null;
          openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, activeCardStrumenti, tags);
        }, 2999);
      });
    }
  }

  function deleteAudioFile(type) {
    const storage = getStorage();
    const filepath = 'files/' + activeCardId + "_" + type + ".mp3";
    deleteObject(ref(storage, filepath)).then((snapshot) => {
      setTimeout(() => {
        openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, activeCardStrumenti, tags);
      }, 2999);
    });
  }

  function playAudioFile(type) {
    const storage = getStorage();
    const audioRef = ref(storage, 'files/' + activeCardId + "_" + type + ".mp3");
    const audioPlayer = document.getElementById('audioPlayer');
    audioPlayer.src = "";
    getDownloadURL(audioRef).then((url) => {
      audioPlayer.src = url;
      audioPlayer.play();
    }).catch((error) => {
      console.error('Error getting download URL:', error);
    });
  }

  async function setIsFileUploaded(type, id) {
    const storage = getStorage();
    const audioRef = ref(storage, 'files/' + id + "_" + type + ".mp3");
    getDownloadURL(audioRef).then((url) => {
      switch (type) {
        case 'bassi': setIsBassiUploaded(true); break;
        case 'bassi1': setIsBassi1Uploaded(true); break;
        case 'bassi2': setIsBassi2Uploaded(true); break;
        case 'tenori': setIsTenoriUploaded(true); break;
        case 'tenori1': setIsTenori1Uploaded(true); break;
        case 'tenori2': setIsTenori2Uploaded(true); break;
        case 'soprani': setIsSopraniUploaded(true); break;
        case 'soprani1': setIsSoprani1Uploaded(true); break;
        case 'soprani2': setIsSoprani2Uploaded(true); break;
        case 'contralti': setIsContraltiUploaded(true); break;
        case 'contralti1': setIsContralti1Uploaded(true); break;
        case 'contralti2': setIsContralti2Uploaded(true); break;
        case 'coro': setIsCoroUploaded(true); break;
        case 'coro1': setIsCoro1Uploaded(true); break;
        case 'coro2': setIsCoro2Uploaded(true); break;
        case 'salmista': setIsSalmistaUploaded(true); break;
        case 'salmista1': setIsSalmista1Uploaded(true); break;
        case 'salmista2': setIsSalmista2Uploaded(true); break;
        case 'solista': setIsSolistaUploaded(true); break;
        case 'solista1': setIsSolista1Uploaded(true); break;
        case 'solista2': setIsSolista2Uploaded(true); break;
      }
    }).catch((error) => {
      switch (type) {
        case 'bassi': setIsBassiUploaded(false); break;
        case 'bassi1': setIsBassi1Uploaded(false); break;
        case 'bassi2': setIsBassi2Uploaded(false); break;
        case 'tenori': setIsTenoriUploaded(false); break;
        case 'tenori1': setIsTenori1Uploaded(false); break;
        case 'tenori2': setIsTenori2Uploaded(false); break;
        case 'soprani': setIsSopraniUploaded(false); break;
        case 'soprani1': setIsSoprani1Uploaded(false); break;
        case 'soprani2': setIsSoprani2Uploaded(false); break;
        case 'contralti': setIsContraltiUploaded(false); break;
        case 'contralti1': setIsContralti1Uploaded(false); break;
        case 'contralti2': setIsContralti2Uploaded(false); break;
        case 'coro': setIsCoroUploaded(false); break;
        case 'coro1': setIsCoro1Uploaded(false); break;
        case 'coro2': setIsCoro2Uploaded(false); break;
        case 'salmista': setIsSalmistaUploaded(false); break;
        case 'salmista1': setIsSalmista1Uploaded(false); break;
        case 'salmista2': setIsSalmista2Uploaded(false); break;
        case 'solista': setIsSolistaUploaded(false); break;
        case 'solista1': setIsSolista1Uploaded(false); break;
        case 'solista2': setIsSolista2Uploaded(false); break;
      }
    });
  }

  function triggerPdfFileInput() {
    document.getElementById('fileInputPdf').click();
  }

  function triggerTestoFileInput() {
    document.getElementById('fileInputTesto').click();
  }

  function triggerStrumentiFileInput() {
    document.getElementById('fileInputStrumenti').click();
  }

  function handlePdfFileUpload() {
    $("#pdfUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputPdf');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + selectedPdfName;
    if (selectedFile) {
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#pdfUploadStatus").html("Caricamento completato");
        setActiveCardPdf(filepath);
        document.getElementById('fileInputPdf').value = null;
        openCard(activeCardTitle, filepath, activeCardId, activeCardTesto, activeCardStrumenti, tags);
        setTimeout(() => {
          $("#pdfUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function handleTestoFileUpload() {
    $("#testoUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputTesto');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + appendSuffixToFilename(selectedPdfName, "_testo");
    if (selectedFile) {
      updateDoc(doc(query(collection(db, "chants")), activeCardId), {
        ["testo"]: filepath
      });
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#testoUploadStatus").html("Caricamento completato");
        setActiveCardTesto(filepath);
        document.getElementById('fileInputTesto').value = null;
        openCard(activeCardTitle, activeCardPdf, activeCardId, filepath, activeCardStrumenti, tags);
        setTimeout(() => {
          $("#testoUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function handleStrumentiFileUpload() {
    $("#strumentiUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputStrumenti');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + appendSuffixToFilename(selectedPdfName, "_strumenti");
    if (selectedFile) {
      updateDoc(doc(query(collection(db, "chants")), activeCardId), {
        ["strumenti"]: filepath
      });
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#strumentiUploadStatus").html("Caricamento completato");
        setActiveCardStrumenti(filepath);
        document.getElementById('fileInputStrumenti').value = null;
        openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, filepath, tags);
        setTimeout(() => {
          $("#strumentiUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function editChantTitlePrompt() {
    let newTitle = prompt('Inserisci il nuovo nome del canto');
    if (newTitle == "" || newTitle == null) {
      return;
    }
    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now(),
      ["title"]: newTitle
    })
    setActiveCardTitle(newTitle);
  }

  async function getDocumentsWithTags() {
    // Query documents where 'tags' contains any element
    const q = query(collection(db, 'chants'), where('tags', '!=', []));
    const querySnapshot = await getDocs(q);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push(doc.data());
    });

    return documents;
  }

  function addTagFromSuggestion(target) {
    const newTag = $(target).html();
    $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
    setTags(prevTags => {
      const updatedTags = prevTags ? [...prevTags, newTag] : [newTag]
      updateTagsInFirestore(updatedTags);
      return updatedTags;
    });
  }

  useEffect(() => {
    // jQuery keypress handler
    $("#textBox").keypress(function (e) {
      if (e.which === 13 && this.value !== "") {
        const newTag = this.value;
        $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
        setTags(prevTags => {
          const updatedTags = prevTags ? [...prevTags, newTag] : [newTag]
          updateTagsInFirestore(updatedTags);
          setTagInput("");
          return updatedTags;
        });
      }
    });

    // jQuery click handler
    $(document).on('click', '.tag span', function () {
      const tagValue = $(this).parent().text().replace('✖', '').trim();
      setTags(prevTags => {
        const indexToRemove = prevTags.indexOf(tagValue);
        if (indexToRemove !== -1) {
          const updatedTags = prevTags.slice(0, indexToRemove).concat(prevTags.slice(indexToRemove + 1));
          updateTagsInFirestore(updatedTags);
          return updatedTags;
        }
      });
      $(this).parent().remove();
    });

    // Cleanup on component unmount
    return () => {
      $("#textBox").off('keypress');
      $(document).off('click', '.tag span');
    };
  }, [activeCardId, tags]); // Use activeCardId and tags as dependencies

  const updateTagsInFirestore = (updatedTags) => {
    if (activeCardId) {
      const docRef = doc(db, "chants", activeCardId);
      updateDoc(docRef, { tags: updatedTags, ["modified"]: -Date.now() })
        .then(() => updateTagsSuggestions(updatedTags))
        .catch(error => console.error("Error updating tags: ", error));
    } else {
      console.error("Invalid activeCardId");
    }
  };

  return (
    <div className="flex w-full ml-52 px-16 pb-16 flex-col">
      <AddChantLayout reloadComponent={reloadComponent} reloadLatestChant={reloadLatestChant} />
      <CardsWrap classes="wrapChants" onClick={openCard} ref={childRef} />
      <div id="card" className="overlay">
        <a href="#" className="closebtn" onClick={closeCard}>×</a>
        <a href="#" className="mx-2 my-5 h-8 closebtn" onClick={openDeleteDialog}><FaTrashAlt className="h-8 closebtn" /></a>
        <div className="overlay-content">
          <input type="file" id="fileInputPdf" accept=".pdf" className="hidden" onChange={handlePdfFileUpload} />
          <input type="file" id="fileInputTesto" accept=".pdf" className="hidden" onChange={handleTestoFileUpload} />
          <input type="file" id="fileInputStrumenti" accept=".pdf" className="hidden" onChange={handleStrumentiFileUpload} />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerPdfFileInput()} id="replacePdfInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Cambia PDF</div>
            <span id="pdfUploadStatus"></span>
          </div>
          <br />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerTestoFileInput()} id="replaceTestoInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Testo</div>
            <span id="testoUploadStatus"></span>
          </div>
          <br />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerStrumentiFileInput()} id="replaceStrumentiInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Strumenti</div>
            <span id="strumentiUploadStatus"></span>
          </div>
          <div className="h2_container"><h2>{activeCardTitle}</h2><FaEdit className="h-8 w-8" id="editChantTitle" onClick={() => editChantTitlePrompt()}></FaEdit></div>
          <div className="tags_container">
            Aggiungi tag:
            <div>
              <input type="textbox" id="textBox" autoComplete="off" value={tagInput} onChange={(e) => setTagInput(e.target.value)}></input>
              <ul className="tagsSuggestionList">
                {filteredTagsSuggestions.map((suggestion, index) => (
                  <li className="tagSuggestion" onClick={(e) => addTagFromSuggestion(e.target)} key={index}>{suggestion}</li>
                ))}
              </ul>
            </div>
            <br />
            <div id="chantTags" contentEditable="false"></div>
          </div>
          <div className="audioFiles audioFilesContainer">
            <div className="audioFiles">
              <div className={isBassiUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("bassi")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />BAS</div>
              <div className={isBassi1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("bassi1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />BAS1</div>
              <div className={isBassi2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("bassi2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />BAS2</div>
            </div>
            <div className="audioFiles">
              <div className={isTenoriUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("tenori")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />TEN</div>
              <div className={isTenori1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("tenori1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />TEN1</div>
              <div className={isTenori2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("tenori2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />TEN2</div>
            </div>
            <div className="audioFiles">
              <div className={isSopraniUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("soprani")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOP</div>
              <div className={isSoprani1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("soprani1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOP1</div>
              <div className={isSoprani2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("soprani2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOP2</div>
            </div>
            <div className="audioFiles">
              <div className={isContraltiUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("contralti")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />CON</div>
              <div className={isContralti1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("contralti1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />CON1</div>
              <div className={isContralti2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("contralti2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />CON2</div>
            </div>
            <div className="audioFiles">
              <div className={isCoroUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("coro")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />COR</div>
              <div className={isCoro1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("coro1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />COR1</div>
              <div className={isCoro2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("coro2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />COR2</div>
            </div>
            <div className="audioFiles">
              <div className={isSalmistaUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("salmista")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SAL</div>
              <div className={isSalmista1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("salmista1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SAL1</div>
              <div className={isSalmista2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("salmista2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SAL2</div>
            </div>
            <div className="audioFiles">
              <div className={isSolistaUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("solista")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOL</div>
              <div className={isSolista1Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("solista1")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOL1</div>
              <div className={isSolista2Uploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("solista2")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />SOL2</div>
            </div>
          </div>
          <audio id="audioPlayer" controls></audio>
          <div className="audioFilesUpload audioFilesContainer">
            <input type="file" id="fileInputAudio" accept=".mp3" className="hidden" onChange={handleFileUpload} />
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("bassi")} className={isBassiUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />BAS</div>
              <div onClick={() => triggerFileInput("bassi1")} className={isBassi1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />BAS1</div>
              <div onClick={() => triggerFileInput("bassi2")} className={isBassi2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />BAS2</div>
              <div onClick={isBassiUploaded ? () => deleteAudioFile("bassi") : null} className={isBassiUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />BAS</div>
              <div onClick={isBassi1Uploaded ? () => deleteAudioFile("bassi1") : null} className={isBassi1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />BAS1</div>
              <div onClick={isBassi2Uploaded ? () => deleteAudioFile("bassi2") : null} className={isBassi2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />BAS2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("tenori")} className={isTenoriUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />TEN</div>
              <div onClick={() => triggerFileInput("tenori1")} className={isTenori1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />TEN1</div>
              <div onClick={() => triggerFileInput("tenori2")} className={isTenori2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />TEN2</div>
              <div onClick={isTenoriUploaded ? () => deleteAudioFile("tenori") : null} className={isTenoriUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />TEN</div>
              <div onClick={isTenori1Uploaded ? () => deleteAudioFile("tenori1") : null} className={isTenori1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />TEN1</div>
              <div onClick={isTenori2Uploaded ? () => deleteAudioFile("tenori2") : null} className={isTenori2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />TEN2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("soprani")} className={isSopraniUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOP</div>
              <div onClick={() => triggerFileInput("soprani1")} className={isSoprani1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOP1</div>
              <div onClick={() => triggerFileInput("soprani2")} className={isSoprani2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOP2</div>
              <div onClick={isSopraniUploaded ? () => deleteAudioFile("soprani") : null} className={isSopraniUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOP</div>
              <div onClick={isSoprani1Uploaded ? () => deleteAudioFile("soprani1") : null} className={isSoprani1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOP1</div>
              <div onClick={isSoprani2Uploaded ? () => deleteAudioFile("soprani2") : null} className={isSoprani2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOP2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("contralti")} className={isContraltiUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />CON</div>
              <div onClick={() => triggerFileInput("contralti1")} className={isContralti1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />CON1</div>
              <div onClick={() => triggerFileInput("contralti2")} className={isContralti2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />CON2</div>
              <div onClick={isContraltiUploaded ? () => deleteAudioFile("contralti") : null} className={isContraltiUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />CON</div>
              <div onClick={isContralti1Uploaded ? () => deleteAudioFile("contralti1") : null} className={isContralti1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />CON1</div>
              <div onClick={isContralti2Uploaded ? () => deleteAudioFile("contralti2") : null} className={isContralti2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />CON2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("coro")} className={isCoroUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />COR</div>
              <div onClick={() => triggerFileInput("coro1")} className={isCoro1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />COR1</div>
              <div onClick={() => triggerFileInput("coro2")} className={isCoro2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />COR2</div>
              <div onClick={isCoroUploaded ? () => deleteAudioFile("coro") : null} className={isCoroUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />COR</div>
              <div onClick={isCoro1Uploaded ? () => deleteAudioFile("coro1") : null} className={isCoro1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />COR1</div>
              <div onClick={isCoro2Uploaded ? () => deleteAudioFile("coro2") : null} className={isCoro2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />COR2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("salmista")} className={isSalmistaUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SAL</div>
              <div onClick={() => triggerFileInput("salmista1")} className={isSalmista1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SAL1</div>
              <div onClick={() => triggerFileInput("salmista2")} className={isSalmista2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SAL2</div>
              <div onClick={isSalmistaUploaded ? () => deleteAudioFile("salmista") : null} className={isSalmistaUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SAL</div>
              <div onClick={isSalmista1Uploaded ? () => deleteAudioFile("salmista1") : null} className={isSalmista1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SAL1</div>
              <div onClick={isSalmista2Uploaded ? () => deleteAudioFile("salmista2") : null} className={isSalmista2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SAL2</div>
            </div>
            <div className="audioFilesUpload">
              <div onClick={() => triggerFileInput("solista")} className={isSolistaUploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOL</div>
              <div onClick={() => triggerFileInput("solista1")} className={isSolista1Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOL1</div>
              <div onClick={() => triggerFileInput("solista2")} className={isSolista2Uploaded ? "buttonFileUpload buttonFileUploaded" : "buttonFileUpload"}><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />SOL2</div>
              <div onClick={isSolistaUploaded ? () => deleteAudioFile("solista") : null} className={isSolistaUploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOL</div>
              <div onClick={isSolista1Uploaded ? () => deleteAudioFile("solista1") : null} className={isSolista1Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOL1</div>
              <div onClick={isSolista2Uploaded ? () => deleteAudioFile("solista2") : null} className={isSolista2Uploaded ? "buttonFileUpload" : "buttonFileUpload buttonFileUploadDisabled"}><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />SOL2</div>
            </div>
          </div>
          <iframe src={activeCardPdf + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
          <iframe src={activeCardTesto + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
          <iframe src={activeCardStrumenti + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
        </div>
      </div>
    </div>
  );
}

export default Chants;
